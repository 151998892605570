import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }
  async GetModuleMasterIdByModuleName(params) {
    return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?" + params);
  }
  async CheckPrivilege(params) {
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async GetClientByCompanyId(param) {
    return await http.instance.get("/GetCompanyParentClientCustomListByCompanyIdForGroup?" + param);
  }
  async GetStateList(param) {
    return await http.instance.get("/GetStateListByCountryCode?" + param);
  }
  async AddMeter(data) {
    return await http.instance.post("/AddMeter", data);
  }
  async GetMeterServices() {
    return await http.instance.get("/GetMeterServices");
  }
  async GetMeterCategories() {
    return await http.instance.get("/GetMeterCategories");
  }
  async GetMeterUnits() {
    return await http.instance.get("/GetMeterUnit");
  }
  async GetMeterVendors(param) {
    return await http.instance.get("/GetMeterVendors?" + param);
  }
  async GetVendorDeatils(param) {
    return await http.instance.get("/GetVendorDeatils?" + param);
  }
  async GetMeterListing() {
    return await http.instance.get("/GetMeterListing");
  }
  async GetPlanListing(param) {
    return await http.instance.get("/GetPlanListing?" + param);
  }
  async GetGenerateBillListing(param) {
    return await http.instance.get("/GetGenerateBillListing?" + param);
  }
  async GetDDLData(params) {
    return await http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async AddUpdatePlan(params) {
    return await http.instance.post("/AddUpdatePlan", params);
  }
  async CreateBillingBatchProcess(params) {
    return await http.instance.post("/CreateBillingBatchProcess", params);
  }
  async GetBatchProcessingListing(params) {
    return await http.instance.post("/GetBatchProcessingListing",params);
  }
  CommonCommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }
  CommonUpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  async GetServiceListing(params) {
    return await http.instance.get("/GetServiceListing?" + params);
  }
  AddSelectedService(params) {
    return http.instance.post("/AddSelectedService", params)
  }
  async GetPlanServiceListing(params) {
    return await http.instance.get("/GetPlanServiceListing?" + params);
  }
  async AddServiceForm(params) {
    return await http.instance.post("/AddServiceForm?" + params);
  }
  async CreateNewService(params) {
    return await http.instance.post("/CreateNewService?" + params);
  }
  async GetInventoryList(params) {
    return await http.instance.get("/GetInventoryList?" + params);
  }
  AddSelectedInventory(params) {
    return http.instance.post("/AddSelectedInventory", params)
  }
  async GetPlanInventoryListing(params) {
    return await http.instance.get("/GetPlanInventoryListing?" + params);
  }
  async GetPlanRateListing(params) {
    return await http.instance.get("/GetPlanRateListing?" + params);
  }
  SavePlanInventory(params) {
    return http.instance.post("/SavePlanInventory", params)
  }
  async displayPlanDetails(params) {
    return await http.instance.get("/displayPlanDetails?" + params);
  }
  SavePlanRate(params) {
    return http.instance.post("/SavePlanRate", params)
  }
  async GetBillingServiceListing(params) {
    return await http.instance.get("/GetBillingServiceListing?" + params);
  }
  async GetServiceListById(params) {
    return await http.instance.get("/GetServiceListById?" + params);
  }
  AddUpdateStation(params) {
    return http.instance.post("/AddUpdateStation?", params)
  }
  async GetStationList() {
    return await http.instance.get("/GetStationList" );
  }
  async GetInvoiceLogListing(params) {
    return await http.instance.post("/GetInvoiceLogListing",params);
  }
  async DeleteStationData(params) {
    return await http.instance.post("/DeleteStation?" +params);
  }
  async UpdateStationStatus(params) {
    return await http.instance.post("/UpdateStationStatus?" +params);
  }
  async SaveGCVValue(params) {
    return await http.instance.post("/SaveGCVValue",params);
  }
  async GetGCVByStationId(params) {
    return await http.instance.get("/GetGCVByStationId?" +params);
  }
  async SaveBillingCycle(params) {
    return await http.instance.post("/SaveBillingCycle?" + params);
  }
  async GetBillingCycleData(params) {
    return await http.instance.get("/GetBillingCycleData?" +params);
  }
  async SaveEMIConfiguration(params) {
    return await http.instance.post("/SaveEMIConfiguration?" + params);
  }
  async GetEMIOptionSettingData(params) {
    return await http.instance.get("/GetEMIOptionSettingData?" +params);
  }
  async CheckExtraFeatures(params) {
    return await http.instanceCommon.get("/Wizard/CheckExtraFeatures?" +params);
  }
  async SaveRateOnBehalfOfPlan(params) {
    return await http.instance.post("/SaveRateOnBehalfOfPlan", params);
  }
  async CallProcessBatchData(params) {
    return await http.instance.get("/CallProcessBatchData?" + params);
  }
  async GetBatchProgress(params) {
    return await http.instance.get("/GetBatchProgress?" + params);
  }
  async CallProcessBatchDataTask(params) {
    return await http.instance.get("/BillingTask/CallProcessBatchData?" + params);
  }
  async GetClientListMappedWithPlan(params) {
    return await http.instance.get("/GetClientListMappedWithPlan?" + params);
  }
  async GetPlanListMappedWithService(params) {
    return await http.instance.get("/GetPlanListMappedWithService?" + params);
  }
  async GetIntegrationAuthorizationUrl(params){
    return await http.instanceXero.get("/Authorization/GetAuthorizationUrl?" + params);
  }
  async SaveTokenDetail(params){
    return await http.instanceXero.get("/Authorization/SaveToken?" + params);
  }
  async InsertAccountIntegration(params){
    return await http.instance.post("/Invoice/InsertAccountIntegration?" + params);
  }
  async GetOuthList(params){
    return await http.instance.get("/Invoice/GetAccountIntegrationList?" + params);
  }
  async DeleteIntegration(params){
    return await http.instance.get("/Invoice/GetAccountIntegrationList?" + params);
  }
}

export default new DataService();
