 //const envType = "qatest";
//const envType = "development";
//const envType = "Live";
const envType = "staging";

var appUrls = [];
// Define your array of URLs

if (envType == "development") {
  appUrls = [
    {
      loginurl: "oidc.user:https://devlogin.talygen.devlabs.com:talygenspa",
      //loginurl: "oidc.user:https://stagelogin.talygen.com:talygenspa",
      //loginurl: "oidc.user:https://applogin.talygen.com:talygenspa",
      navbarapp: "http://localhost:9002/app.js",
      crmapp: "http://localhost:9003/app.js",
      expensesapp: "http://localhost:9004/app.js",
      docstorageapp: "http://localhost:9005/app.js",
      ticketingapp: "http://localhost:9006/app.js",
      assetmanagementapp: "http://localhost:9007/app.js",
      commonuiapp: "http://localhost:9008/app.js",
      timerapp: "http://localhost:9009/app.js",
      approvalmoduleapp: "http://localhost:9010/app.js",
      customlayoutapp: "http://localhost:9011/app.js",
      companysetupapp: "http://localhost:9012/app.js",
      ucaasapp: "http://localhost:9013/app.js",
      billingapp: "http://localhost:9014/app.js",
      WebBuildersetupapp: "http://localhost:9015/app.js",
      hrsetupapp: "http://localhost:9016/app.js",
      performancereview: "http://localhost:9017/app.js",
      talygenreports: "http://localhost:9018/app.js",
      projectuiapp: "http://localhost:9019/app.js",
      // "navbar":"http://localhost:9002/",
      // "ticketing":"http://localhost:9006/",
      // "crm":"http://localhost:9003/",
      // "expenses":"http://localhost:9004/",
      // "docstorage":"http://localhost:9005/",
      // "assetmanagement":"http://localhost:9007/",
      // "authservice":"http://localhost:9001/",
      // "commonui":"http://localhost:9008/",
      // "timer":"http://localhost:9009/",
      // "approvalmodule":"http://localhost:9010/",
      // "customlayout":"http://localhost:9011/",
      // "companysetup":"http://localhost:9012/"
    },
  ];
} else if (envType == "staging") {
  appUrls = [
    {
      loginurl: "oidc.user:https://stagelogin.talygen.com:talygenspa",
      navbarapp: "https://tlgnbucket.s3.amazonaws.com/Stage/NavBar/dist/app.js",
      crmapp: "https://tlgnbucket.s3.amazonaws.com/Stage/CRM/dist/app.js",
      expensesapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/Expense/dist/app.js",
      docstorageapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/DocStorage/dist/app.js",
      ticketingapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/Ticketing/dist/app.js",
      assetmanagementapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/Asset/dist/app.js",
      commonuiapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/CommonUi/dist/app.js",
      timerapp: "https://tlgnbucket.s3.amazonaws.com/Stage/Timer/dist/app.js",
      approvalmoduleapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/Approval/dist/app.js",
      customlayoutapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/CustomLayout/dist/app.js",
      companysetupapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/CompanySetup/dist/app.js",
      billingapp:
        "https://tlgnbucket.s3.amazonaws.com/Stage/Billing/dist/app.js",
      ucaasapp: "https://tlgnbucket.s3.amazonaws.com/Stage/Ucaas/dist/app.js",
      talygenreports: "https://tlgnbucket.s3.amazonaws.com/Stage/TalygenReports/dist/app.js",
      hrsetupapp: "https://tlgnbucket.s3.amazonaws.com/Stage/HR/dist/app.js",
      performancereview: "https://tlgnbucket.s3.amazonaws.com/Stage/PerformanceReview/dist/app.js",
      // "vue": "https://cdn.jsdelivr.net/npm/vue@2.6.11/dist/vue.min.js",
      // "root-config/": "http://localhost:9000/",
      // "docstorage":"https://talygenv.github.io/talygenui/Stage/DocStorage/dist/",
      // "navbar":"https://talygenv.github.io/talygenui/Stage/NavBar/dist/",
      // "crm":"https://talygenv.github.io/talygenui/Stage/CRM/dist/",
      // "expenses" :"https://talygenv.github.io/talygenui/Stage/Expense/dist/",
      // "ticketing":"https://talygenv.github.io/talygenui/Stage/ticketing/dist/",
      // "assetmanagement":"https://talygenv.github.io/talygenui/Stage/AssetManagement/dist/",
      // "commonui":"https://talygenv.github.io/talygenui/Stage/CommonUi/dist/",
      // "timer":"https://talygenv.github.io/talygenui/Stage/Timer/dist/",
      // "approvalmodule":"https://talygenv.github.io/talygenui/Stage/Approval/dist/",
      // "customlayout":"https://talygenv.github.io/talygenui/Stage/customlayout/dist/",
      // "companysetup":"https://talygenv.github.io/talygenui/Stage/CompanySetup/dist/",
    },
  ];
} else if (envType == "qatest") {
  appUrls = [
    {
      loginurl: "oidc.user:https://qatestlogin.talygen.com:talygenspa",
      navbarapp:
        "https://assets.talygen.com/Talygen/Build/Qa/NavBar/dist/app.js",
      docstorageapp:
        "https://assets.talygen.com/Talygen/Build/Qa/DocStorage/dist/app.js",
      crmapp: "https://assets.talygen.com/Talygen/Build/Qa/CRM/dist/app.js",
      expensesapp:
        "https://assets.talygen.com/Talygen/Build/Qa/Expense/dist/app.js",
      ticketingapp:
        "https://assets.talygen.com/Talygen/Build/Qa/Ticketing/dist/app.js",
      assetmanagementapp:
        "https://assets.talygen.com/Talygen/Build/Qa/AssetManagement/dist/app.js",
      commonuiapp:
        "https://assets.talygen.com/Talygen/Build/Qa/CommonUi/dist/app.js",
      timerapp: "https://assets.talygen.com/Talygen/Build/Qa/Timer/dist/app.js",
      approvalmoduleapp:
        "https://assets.talygen.com/Talygen/Build/Qa/Approval/dist/app.js",
      customlayoutapp:
        "https://assets.talygen.com/Talygen/Build/Qa/CustomLayout/dist/app.js",
      companysetupapp:
        "https://assets.talygen.com/Talygen/Build/Qa/CompanySetup/dist/app.js",
      billingapp:
        "https://assets.talygen.com/Talygen/Build/Qa/Billing/dist/app.js",
      ucaasapp: "https://assets.talygen.com/Talygen/Build/Qa/Ucaas/dist/app.js",
      hrsetupapp: "https://assets.talygen.com/Talygen/Build/Qa/HR/dist/app.js",
      WebBuildersetupapp:
        "https://assets.talygen.com/Talygen/Build/Qa/WebBuilder/dist/app.js",
      performancereview:
        "https://assets.talygen.com/Talygen/Build/Qa/PerformanceReview/dist/app.js",
      talygenreports:
        "https://assets.talygen.com/Talygen/Build/Qa/TalygenReports/dist/app.js",  
      projectuiapp:
        "https://assets.talygen.com/Talygen/Build/Qa/Project/dist/app.js",  

      // "vue": "https://cdn.jsdelivr.net/npm/vue@2.6.11/dist/vue.min.js",
      // "root-config/": "https://qatestnewui.talygen.com/",
      // "docstorage":"https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/",
      // "authservice/": "https://qatestnewuiauth.talygen.com/" ,
      // "navbar":"https://talygenv.github.io/talygenuiqa/QATestNew/NavBar/dist/",
      // "crm":"https://talygenv.github.io/talygenuiqa/QATestNew/CRM/dist/",
      // "expenses" :"https://talygenv.github.io/talygenuiqa/QATestNew/Expense/dist/",
      // "ticketing":"https://talygenv.github.io/talygenuiqa/QATestNew/Ticketing/dist/",
      // "assetmanagement":"https://talygenv.github.io/talygenuiqa/QATestNew/AssetManagement/dist/",
      // "commonui":"https://talygenv.github.io/talygenuiqa/QATestNew/CommonUi/dist/",
      // "timer":"https://talygenv.github.io/talygenuiqa/QATestNew/Timer/dist/",
      // "approvalmodule":"https://talygenv.github.io/talygenuiqa/QATestNew/Approval/dist/",
      // "customlayout":"https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/",
      // "companysetup":"https://talygenv.github.io/talygenuiqa/QATestNew/CompanySetup/dist/"
    },
  ];
} else if (envType == "UsicTest") {
  appUrls = [
    {
      docstorageapp:
        "https://talygenv.github.io/talygenui/UsicTest/DocStorage/dist/app.js",
      navbarapp:
        "https://talygenv.github.io/talygenui/UsicTest/NavBar/dist/app.j",
      crmapp: "https://talygenv.github.io/talygenui/UsicTest/CRM/dist/app.js",
      expensesapp:
        "https://talygenv.github.io/talygenui/UsicTest/Expense/dist/app.js",
      assetmanagementapp:
        "https://talygenv.github.io/talygenui/UsicTest/AssetManagement/dist/app.js",
      commonuiapp:
        "https://talygenv.github.io/talygenui/UsicTest/CommonUi/dist/app.js",
      billingapp:
        "https://talygenv.github.io/talygenui/UsicTest/Billing/dist/app.js",
      ucaasapp:
        "https://talygenv.github.io/talygenui/UsicTest/Ucaas/dist/app.js",
      // "vue": "https://cdn.jsdelivr.net/npm/vue@2.6.11/dist/vue.min.js",
      // "root-config/": "https://usictestnewui.talygen.com/",
      // "navbar/": "https://talygenv.github.io/talygenui/UsicTest/NavBar/dist/",
      // "crm/": "https://talygenv.github.io/talygenui/UsicTest/CRM/dist/",
      // "expenses": "https://talygenv.github.io/talygenui/UsicTest/Expense/dist/",
      // "docstorage":"https://talygenv.github.io/talygenui/UsicTest/DocStorage/dist/",
      // "assetmanagement":"https://talygenv.github.io/talygenui/UsicTest/AssetManagement/dist/",
      // "commonui": "https://talygenv.github.io/talygenui/UsicTest/CommonUi/dist/",
      // "authservice/": "https://usictestnewuiauth.talygen.com/"
    },
  ];
} else if (envType == "Live") {
  appUrls = [
    {
      loginurl: "oidc.user:https://applogin.talygen.com:talygenspa",
      navbarapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.21/NavBar/dist/app.js",
      crmapp: "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.2/CRM/dist/app.js",
      expensesapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.0/Expense/dist/app.js",
      docstorageapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.2/DocStorage/dist/app.js",
      ticketingapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.7/Ticketing/dist/app.js",
      assetmanagementapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.3/AssetManagement/dist/app.js",
      commonuiapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.10/CommonUi/dist/app.js",

      timerapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.14/Timer/dist/app.js",
      approvalmoduleapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.0/Approval/dist/app.js",
      customlayoutapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.4/CustomLayout/dist/app.js",
      companysetupapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.28/CompanySetup/dist/app.js",
      billingapp:
        "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.0/Billing/dist/app.js",
      ucaasapp: "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.13/Ucaas/dist/app.js",
      hrsetupapp: "https://d2pvmahzgsgqn.cloudfront.net/prod/v1.3/HR/dist/app.js"
      // "vue": "https://cdn.jsdelivr.net/npm/vue@2.6.11/dist/vue.min.js",
      // "root-config/": "http://localhost:9000/",
      // "docstorage":"https://talygenv.github.io/talygenui/Stage/DocStorage/dist/",
      // "navbar":"https://talygenv.github.io/talygenui/Stage/NavBar/dist/",
      // "crm":"https://talygenv.github.io/talygenui/Stage/CRM/dist/",
      // "expenses" :"https://talygenv.github.io/talygenui/Stage/Expense/dist/",
      // "ticketing":"https://talygenv.github.io/talygenui/Stage/ticketing/dist/",
      // "assetmanagement":"https://talygenv.github.io/talygenui/Stage/AssetManagement/dist/",
      // "commonui":"https://talygenv.github.io/talygenui/Stage/CommonUi/dist/",
      // "timer":"https://talygenv.github.io/talygenui/Stage/Timer/dist/",
      // "approvalmodule":"https://talygenv.github.io/talygenui/Stage/Approval/dist/",
      // "customlayout":"https://talygenv.github.io/talygenui/Stage/customlayout/dist/",
      // "companysetup":"https://talygenv.github.io/talygenui/Stage/CompanySetup/dist/",
    },
  ];
} else if (envType == "UsicNet") {
  appUrls = [
    {
      docstorageapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/DocStorage/dist/app.js",
      navbarapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/NavBar/dist/app.js",
      crmapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/CRM/dist/app.js",
      expensesapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/Expense/dist/app.js",
      assetmanagementapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/AssetManagement/dist/app.js",
      commonuiapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/CommonUi/dist/app.js",
      billingapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/Billing/dist/app.js",
      ucaasapp:
        "https://talygenv.github.io/talygenui/UsicCloudNet/Ucaas/dist/app.js",
    },
  ];
}

export default { appUrls };
