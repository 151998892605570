import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }
  async CheckPrivilege(params) {
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }

  async GetModuleMasterIdByModuleName(params) {
    return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?" + params);
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async SaveHideContextMenuConfig(param) {
    return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?" + param);
  }
  GetPerformanceReviewList(params) {
    return http.instance.get("/Review/GetPerformanceReviewList?" + params);
  }

  GetUserGoalList(params) {
    return http.instance.get("/Goal/UserGoalListing?" + params);
  }

  GetUserNoteList(params) {
    return http.instance.get("/Notes/GetUserNoteList?" + params);
  }
  userFeedbacklisting(params) {
    return http.instance.get("/Feedback/userFeedbacklisting?" + params);
  }
  GetUserSkillList(params) {
    return http.instance.get("/Skill/GetUserSkillList?" + params);
  }
  MyTeamlisting(params) {
    return http.instance.get("/MyTeam/MyTeamlisting?" + params);
  }
  UserReviewGoalListing(params) {
    return http.instance.get("/Review/UserReviewGoalListing?" + params);
  }
  ListQuestion(params) {
    return http.instance.get("/Question/UserListQuestion?" + params);
  }
  GetUserReviewNoteListing(params) {

    return http.instance.get("/Review/UserReviewNoteListing?" + params);
  }
  GetReviewListCA(params) {
    return http.instance.get("/Review/GetReviewListCA?", { params });
  }
  GetReviewListForReviewer(params) {
    return http.instance.get("/Review/GetReviewListForReviewer?" + params);
  }
  CommonUpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
  }
  GetStatusDDLData(params) {
    return http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
  }
  GetUsersByCompanyId(params) {
    return http.instance.get("/Review/GetUsersByCompanyId?" + params);
  }
  GetReviewTemplateByCompanyId() {
    return http.instance.get("/Review/GetReviewTemplateByCompanyId");
  }
  GetReviewer(params) {
    return http.instance.get("/Review/GetReviewer?" + params);
  }
  UserReviewSkillListing(params) {
    return http.instance.get("/Review/UserReviewSkillListing?" + params);
  }
  UserReviewFeedbackListing(params) {
    return http.instance.get("/Review/UserReviewFeedbackListing?" + params);
  }
  AddUpdateReview(params) {
    return http.instance.post("/Review/AddUpdateReview?", { jsonString: params });
  }
  getProvideFeedbackListing() {

    return http.instance.get("/Feedback/ProvideFeedbackListing");
  }
  CommentNoteList(params) {
    return http.instance.get("/Review/CommentList?" + params);
  }
  SaveNoteComment(params) {
    return http.instance.post("/Review/SaveComment?" + params);
  }
  async GetOverViewData(params) {
    return await http.instance.get("/Review/GetOverViewData?" + params);
  }
  async GetUserDetailByUserId(params) {
    return await http.instance.get("/Review/GetUserDetailByUserId?" + params);
  }
  async GetProficiencyHistoryList(params) {
    return await http.instance.get("/Goal/GetProficiencyHistoryList?" + params);
  }
  SavePostcomment(params) {
    return http.instance.post("/Goal/SavePostcomment?", { postString: params });
  }
  SaveGoalComment(params) {
    return http.instance.post("Goal/SaveComment?" + params);
  }
  CommentGoalList(params) {
    return http.instance.get("/Goal/CommentList?" + params);
  }

  async SaveOrSaveAsDraftFeedbackAnswer(params) {
    return await http.instance.post("/Feedback/SaveOrSaveAsDraftFeedbackAnswer?" + params);
  }
  QuestionListing(params) {
    return http.instance.get("/Question/UserQuestionlListing?" + params);
  }
  AddUpdateQuestion(params) {
    return http.instance.post("/Question/AddUpdateQuestion?", { jsonString: params });
  }
  CommonDeleteData(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params })
  }

  async GetReviewTemplateListByReviewId(params) {
    return await http.instance.get("/Review/GetReviewTemplateListByReviewId?" + params);
  }
  async GetOverviewCommentListByReviewId(params) {
    return await http.instance.get("/Review/GetOverviewCommentListByReviewId?" + params);
  }
  SavePostcomment(params) {
    return http.instance.post("/Notes/SavePostcomment?", { postString: params });
  }
  GetCommentById(params) {
    return http.instance.get("/Notes/GetCommentById?" + params);
  }
  ReportMain(params) {
    return http.instanceCommon.post("/Report/ReportMain", { postString: params });
  }

  async CheckSubmitReviewAvailability(params) {
    return await http.instance.get("/Review/CheckSubmitReviewAvailability?" + params);
  }

  async SubmitReview(params) {
    return await http.instance.post("/Review/SubmitReview?", params);
  }
  GetCompanyLocation() {
    return http.instanceCommon.get("/Configuration/GetCompanyLocation");
  }
  GetUserListLocationId(params) {
    return http.instance.get("/Report/GetUserListLocationId?" + params);
  }
  GetUserListByManagerId(params) {
    return http.instance.get("/Report/GetUserListByManagerId?" + params);
  }
  GetShiftByLocation(params) {
    return http.instance.get("/Report/GetShiftByLocation?" + params);
  }
  GetDepartment(params) {
    return http.instance.get("/Report/GetDepartment?" + params);
  }
  GetAllDesignation(params) {
    return http.instance.get("/Report/GetAllDesignation?" + params);
  }
  CommonCommonDelete(params) {
    return http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }

  async GetQuestionListByReviewId(params) {
    return await http.instance.get("/Review/GetQuestionListByReviewId?" + params);
  }
  async SaveAndUpdateQuestion(model, reviewId) {
    return await http.instance.post("/Review/SaveAndUpdateQuestion", { model, reviewId });
  }
  SaveComment(params) {
    return http.instance.post("/Skill/SaveComment?" + params);
  }
  CommentList(params) {
    return http.instance.get("/Skill/CommentList?" + params);
  }
  GetFeedbackById(params) {
    return http.instance.get("/Feedback/GetFeedbackById?" + params);
  }
  SavePostcomments(params) {
    return http.instance.post("/Feedback/SavePostcomments?", { postString: params });
  }
  async AddUpdateGoal(params) {
    return await http.instance.post("/Goal/AddUpdateGoal", params);
  }
  async UpdateUserSkill(params) {
    return await http.instance.get("Skill/UpdateUserSkill?" + params);
  }
  GetReviewTemplateList() {
    return http.instance.get("/Review/GetReviewTemplateList");
  }
  GetReviewerList(params) {
    return http.instance.get("/Review/GetReviewerList?" + params);
  }
  GetListFeedbackByReviewId(params) {
    return http.instance.get("/Feedback/GetListFeedbackByReviewId?" + params);
  }
  SaveReview(param) {
    return http.instance.post("/Review/SaveReview", param);
  }
  GetEditReviewerList(param) {
    return http.instance.get("/Review/EditReviewerList?" + param);
  }
  RemoveReviews(param) {
    return http.instance.delete("/Review/RemoveReviews?" + param);
  }

  DeleteGoal(params) {
    return http.instance.delete("/Goal/DeleteGoal?" + params);
  }
  DeleteNote(params) {
    return http.instance.delete("/Notes/DeleteNote?" + params);
  }
  async AddUpdateUserNote(params) {
    return await http.instance.post("/Notes/AddUpdateUserNote?", { postString: params });
  }
  GetFeedbackDetail(params) {
    return http.instance.get("/Feedback/GetFeedbackDetail?" + params);
  }

}
export default new DataService();
